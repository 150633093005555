

<h1 mat-dialog-title fxLayoutAlign="center">Financial Split - {{data.section}}</h1>
<div mat-dialog-content>
    <dx-data-grid class="res-data-grid" height="100%" width="100%" [dataSource]="financialSplit"
      [showBorders]="true" [showRowLines]="true">

      <dxi-column alignment="left" dataField="payer" caption="Payer"></dxi-column>
      <!-- <dxi-column alignment="left" dataField="totalCharge" caption="Total Charge"></dxi-column> -->
      <dxi-column *ngIf="data.section==='Charge'" caption="Amount" dataField="totalCharge" width="150px" alignment="center" cellTemplate="AmountTemplete">
      </dxi-column>
      <dxi-column *ngIf="data.section==='Charge'" caption="Commission" dataField="totalCommission" width="150px" alignment="center" cellTemplate="AmountTemplete">
      </dxi-column>
      <dxi-column *ngIf="data.section==='Paid'" caption="Amount" dataField="totalPaid" width="150px" alignment="center" cellTemplate="AmountTemplete">
      </dxi-column>
      <div *dxTemplate="let cellInfo of 'AmountTemplete'" style="text-align: right">
        {{ cellInfo.value | formatCurrency : data.bkgCurrency }}
      </div>

    </dx-data-grid>
</div>
<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-flat-button type="button" color="primary" (click)="close()">close</button>
</div>
