import { SafeUrl } from "@angular/platform-browser";
import { AppModule } from "@app/app.module";
import { BaseObject, SafeBaseObject } from "@common/models/base-object.models";
import { DateTime } from "luxon";
import { lastValueFrom, map } from "rxjs";
import { ReservationApiService } from "../services/reservation-api.service";
import { IReservationApiPackageEcmData, ReservationPackageEcmData } from "./res-package-detail.model";
import { BookingGuest, BookingPriceJourney, BookingSupplement } from "./res-booking.model";
import { IReservationApiProduct, ReservationProduct } from "./res-product.models";
import { ReservationApiBoolean } from '@app/reservation/models/res-constant.model';
import { IReservationPackageMeal } from "./res-cached-data.models";
import { ResProductPriceType, ReservationProductServiceOptions } from "./res-package.model";
import { IResProductApiPromoPrice, ResProductPromoPrice } from "./res-promo.model";

export class ReservationSuite {

  public suiteCapacity = '';
  public suiteCapacityID = 0;
  public suiteCategory = '';
  public suiteCategoryCode = '';
  public suiteCategoryID = 0;
  public suiteID = 0;
  public suiteNum = '';
  public suiteLabel = ''

  constructor(data?:ReservationCruise) {
    if(data) {
      this.suiteCapacity = data.suiteCapacity
      this.suiteCapacityID = data.suiteCapacityID
      this.suiteCategory = data.suiteCapacity
      this.suiteCategoryCode = data.suiteCapacity
      this.suiteCategoryID = data.suiteCategoryID
      this.suiteID = data.suiteID
      this.suiteNum = data.suiteNum
      this.suiteLabel = data.suiteLabel
    }
  }
}
export class ReservationCruise extends ReservationProduct {
  availabilityMessage = '';
  availabilityBackgroundColor = '';
  public cruiseLine = '';
  public cruiseLineCod = '';
  public cruiseLineID = 0;
  public currency = '';
  public debarkPortID = 0;
  public debarkDate: DateTime | undefined;
  public debarkFrom: DateTime | undefined;
  public debarkTo: DateTime | undefined;
  public debarkDateTime: DateTime | undefined;
  public debarkPort = '';
  public debarkIataCod = '';
  public description = '';
  public destinationID = 0;
  destinationName: string = '';
  public duration = 0;
  public daysToSailing = 0;
  public embarkPortID = 0;
  public embarkDate: DateTime | undefined;
  public embarkFrom: DateTime | undefined;
  public embarkTo: DateTime | undefined;
  public embarkFromDateTime: DateTime | undefined;
  public embarkToDateTime: DateTime | undefined;
  public embarkPort = '';
  public embarkIataCod = '';
  public sailDate: DateTime | undefined;
  public sailTime: DateTime | undefined;
  public sailDateTime: DateTime | undefined;
  public isOverNight = false;
  isAnchor = false
  public features: Array<ReservationCruiseFeature> | undefined;
  public gty: boolean | undefined;
  public itineraries: Array<ReservationCruiseItinerary> | undefined;
  public minGuest = 1;
  public maxGuest = 6;
  public pathDeckplan = '';
  public paxNum = 0;
  portCharge: number = 0;
  public priceTypeID = 0;
  public priceTypeName = '';
  public priceFrom = 0;
  public priceCombo = 0;
  public sellingPrice = 0;
  public priceCategoryID = 0;
  public priceCategoryCod = '';
  public priceCategory = '';
  public bkgPriceJourney?: BookingPriceJourney;
  public packageID = 0;
  public packageTravelDateID = 0;
  public pricePerGuest = 0;
  public priceChild = 0;
  public priceExtraChild = 0;
  public sectionID = 0;
  selectable: boolean = true;
  public sellingPriceCurrency = '';
  public services: Array<ReservationCruiseService> | undefined;
  public ship = '';
  public shipCod = '';
  public shipID = 0;
  subDestinationID: number = 0;
  subDestinationName: string = '';
  public suiteCapacity = '';
  public suiteCapacityID = 0;
  public suiteCategory = '';
  public suiteCategoryCode = '';
  public suiteCategoryID = 0;
  public suiteID = 0;
  public suiteNum = '';
  public suiteLabel = ''
  public voyageID = 0;
  public voyIDPrev: number | undefined
  public voyIDNext: number | undefined
  public voyageClass = ''
  public voyageName = '';
  public voyageNumber = '';
  public paymentDueDate: Date | undefined;
  public fullBookingSchedulingFlag: boolean | undefined;
  public amount = 0;
  public isFinalPayment: boolean | undefined;
  public imageSrc: SafeUrl = '';
  public supplements: Array<BookingSupplement> | undefined;
  public deckPlanStartFrom?: DateTime;
  public ecmData = new ReservationPackageEcmData();

  public refurbStatus = '';
  public obstruction = '';
  public obsPercentage = 0;
  public obsReason = '';

  public scales = '';
  public checked = false;
  public isDeletable = false;
  public internalType = 'voyage';
  public netFareAvailable = false;
  public checkable?= true;
  keySuiteID = 0
  roomID = 0
  productType = ''
  private reservationApiService: ReservationApiService;

  hasPromo: boolean = false;
  promoPrices:ResProductPromoPrice[] = [];
  priceTypes:ResProductPriceType[] = []
  selectedPromoID?:number;
  hasBundle: boolean = false;
  promoStartDate?:DateTime;
  promoEndDate?:DateTime;
  ada = false

  constructor(data?: IReservationApiCruise) {
    super(data);
    this.reservationApiService = AppModule.injector.get(ReservationApiService);
    this.supplements = [];
    this.ecmData = new ReservationPackageEcmData();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }

  get hasMediaData() {
    return this.ecmData.loaded
  }
  get hasImages() {
    return this.ecmData.images.length > 0
  }
  get hasMap() {
    return !!this.ecmData.imageMapSafe
  }
  get mapUrl() {
    return this.ecmData.imageMapSafe
  }
  get imagesUrl() {
    return this.ecmData.imagesSafe
  }
  get hasHtmlDescription() {
    return !!this.ecmData.descriptionSafe
  }
  get descriptionHtml() {
    return this.ecmData.descriptionSafe
  }
  override updateData(data: IReservationApiCruise): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty("debarkDate", data, "DebarkDate");
    this.addDateTimeProperty("embarkDate", data, "EmbarkDate");
    this.addDateTimeProperty("debarkFrom", data, "DebarkFrom");
    this.addDateTimeProperty("debarkTo", data, "DebarkTo");
    this.addDateTimeProperty("embarkFrom", data, "EmbarkFrom");
    this.addDateTimeProperty("embarkTo", data, "EmbarkTo");
    this.addDateTimeProperty("sailDate", data, "SailDate");
    this.addDateTimeProperty("sailTime", data, "SailTime");
    this.addDateTimeProperty("deckPlanStartFrom", data, "DeckPlanStartFrom");
    this.addDateTimeProperty("promoStartDate", data, "PromoStartDate");
    this.addDateTimeProperty("promoEndDate", data, "PromoEndDate");
    this.addBooleanProperty("gty", data, "GTY");
    this.addBooleanProperty("isOverNight", data, "IsOverNight");
    this.addBooleanProperty("hasPromo", data, "HasPromo");
    this.addBooleanProperty("ada", data, "Ada");
    this.addProperty("priceFrom", data, "Price");
    this.addProperty("suiteCategoryCode", data, "SuiteCategoryCod");
    this.addProperty("suiteNum", data, "SuiteNo");

    if (this.sailDate && this.sailTime) this.sailDateTime = this.sailDate.set({ hour: this.sailTime.hour, minute: this.sailTime.minute });
    if (this.embarkDate) {
      if (this.embarkFrom) this.embarkFromDateTime = this.embarkDate.set({ hour: this.embarkFrom.hour, minute: this.embarkFrom.minute });
      if (this.embarkTo) this.embarkToDateTime = this.embarkDate.set({ hour: this.embarkTo.hour, minute: this.embarkTo.minute });
    }
    if (this.debarkDate && this.debarkFrom) this.debarkDateTime = this.debarkDate.set({ hour: this.debarkFrom.hour, minute: this.debarkFrom.minute });

    this.addProperty("voyageID", data, "VoyageID");
    if (!this.voyageID) {
      this.addProperty("voyageID", data, "VoyID");
    }

    this.addProperty("voyIDNext", data, "VoyageIDNext");
    this.addProperty("voyIDPrev", data, "VoyageIDPrev");
    this.addDateTimeProperty("paymentDueDate", data, "PaymentDueDate");
    this.addBooleanProperty("fullBookingSchedulingFlag", data, "FullBookingSchedulingFlag");
    this.addBooleanProperty("isFinalPayment", data, "IsFinalPayment");
    this.addBooleanProperty("selectable", data, "Selectable");

    //childs
    this.features = [];
    this.itineraries = [];
    this.services = []
    this.ecmData = new ReservationPackageEcmData();

    this.imageSrc = '/assets/images/reservation/cruises-search/CruiseHeader01.jpg';

    if (data.Features) this.features = data.Features.map(feature => new ReservationCruiseFeature(feature));
    if (data.Itineraries) this.itineraries = data.Itineraries.map(itinerary => new ReservationCruiseItinerary(itinerary));
    if (data.Services) this.services = data.Services.map(service => new ReservationCruiseService(service));
    if (data.EcmData) this.ecmData = ReservationPackageEcmData.mapEcmData(data.EcmData);
    if (data.PriceTypes) this.priceTypes = data.PriceTypes.map(pt=> new ResProductPriceType(pt));
    if (data.PromoPrice) this.promoPrices = data.PromoPrice.map(pprice=> new ResProductPromoPrice(pprice));

    this.imageSrc = this.ecmData.imageMapSafe !== '' ? this.ecmData.imageMapSafe : this.imageSrc;
    if ((this.services?.length ?? 0) > 0) {
      const services = this.services?.slice();
      this.services = [];

      services?.filter((item) => {
        const i = this.services?.findIndex((x) => x.serviceTypeName === item.serviceTypeName);
        if ((i ?? -1) <= -1) {
          this.services?.push(item);
        }
      });
    }

    this.services?.map((service) => {
      switch (service.serviceTypeName?.trim()) {
        case 'Accommodation':
          service.serviceIconCode = 'hotel-stroke';
          break;
        case 'Food & Beverage':
          service.serviceIconCode = 'guest-stroke';
          break;
        case 'Transport':
          service.serviceIconCode = 'car-stroke';
          break;
        case 'Flights':
          service.serviceIconCode = 'plane-stroke';
          break;
        case 'Gifts & Merchandising':
          service.serviceIconCode = 'price-stroke';
          break;
        case 'Package':
          service.serviceIconCode = 'work-stroke';
          break;
        case 'Cruise Discount':
          service.serviceIconCode = 'fa-percentage';
          break;
      }
    });
  }
  isLecOrSimilar() {
    return this.packageID>0
  }
  getBackgroundImageUrl(which: string): string {
    return `url("${(this.imageSrc as any).changingThisBreaksApplicationSecurity ?? '/assets/images/reservation/cruises-search/CruiseHeader' + which + '.jpg'}")`;
  }
  displayName(): string {
    return this.voyageName ?? this.voyageNumber ?? `${this.voyageID}` ?? '';
  }
  displayBrand(): string {
    return this.cruiseLine;
  }
  getStartDate(): DateTime | undefined {
    if (this.embarkDate && !this.embarkDate.isValid) return undefined
    return this.embarkDate;
  }
  getEndDate(): DateTime | undefined {
    if (this.debarkDate && !this.debarkDate.isValid) return undefined
    return this.debarkDate;
  }
  isValid(): boolean {
    return !!this.voyageID || this.productType==='Camp';
  }

  daysToSail(): number | null {
    if (!this.embarkDate) return null;
    return Math.ceil(this.embarkDate.diff(DateTime.now(), 'days').days);
  }

  belongsToGrandVoyage(grandVoyagePriceType: number | undefined): boolean {
    if (!grandVoyagePriceType) return false;
    return this.priceTypeID === grandVoyagePriceType;
  }

  loadEcmData(): Promise<ReservationPackageEcmData> {
    if (this.ecmData.loaded) return Promise.resolve(this.ecmData);

    return lastValueFrom(this.reservationApiService.getVoyageEcmData(this.voyageID).pipe(
      map<Array<IReservationApiPackageEcmData>, ReservationPackageEcmData>((ecmData) => this.ecmData = ReservationPackageEcmData.mapEcmData(ecmData))
    ));
  }

  getSuiteInfoTooltip() {
    let info = '';
    if (!!this.refurbStatus) info += `Refurb: ${this.refurbStatus}`;
    if (!!this.obstruction) {
      if (info.length > 0) info += ' - ';
      info += `Obstruction view: ${this.obstruction}`
      if (!!this.obsPercentage) {
        info += ` ${this.obsPercentage}%`
      };
      if (!!this.obsReason) {
        info += ` ${this.obsReason}`
      };
    };
    return info;
  }
}

export interface IResApiVoyagesSearchParameters {
  VoyageID?:string;
  VoyageNumber?:string;
  VoyageType?: string;
	ShipName?:string;
  ShipID?:number;
	City?:string;
	DateFrom?:DateTime;
	DateTo?:DateTime;
  SearchText?:string;
  PriceTypeID?:string;
  VoyageID_EXCL_Table?:Array<{VoyageID: number}>;
	PageNum?:number;
	PageSize?:number;
  PromoID?:number;
}
export interface IReservationApiCruise extends IReservationApiProduct {
  AvailabilityMessage: string;
  CruiseLine: string;
  CruiseLineCod: string;
  CruiseLineID: number;
  Currency: string;
  DebarkPortID: number;
  DebarkDate: Date;
  DebarkPort: string;
  DebarkIataCod: string;
  DebarkFrom: string;
  DebarkTo: string;
  Description: string;
  DestinationID: number;
  Detail: Array<IReservationApiCruiseDetail>;
  Duration: number;
  DaysToSailing: number;
  EmbarkPortID: number;
  EmbarkDate: Date;
  EmbarkPort: string;
  EmbarkIataCod: string;
  EmbarkFrom: string;
  EmbarkTo: string;
  DepartTime: string;
  Features: Array<IReservationApiCruiseFeature>;
  GTY: string;
  Itineraries: Array<IReservationApiCruiseItinerary>;
  MinGuest: number;
  MaxGuest: number;
  PackageID?: number;
  PackageTravelDateID?: number;
  PathDeckplan: string;
  PaxNum: number;
  Price: number;
  PriceTypeID: number;
  PriceTypeName: string;
  SectionID?: number;
  SellingPrice?: number;
  PriceCategoryID?: number;
  PriceCategoryCod: string;
  PriceCategory: string;
  PricePerGuest?: number;
  PriceChild?: number;
  PriceCombo?: number;
  PriceExtraChild?: number;
  SellingPriceCurrency?: string;
  Services: Array<IReservationApiCruiseService>;
  Ship: string;
  ShipCod: string;
  ShipID: number;
  SuiteCapacity: string;
  SuiteCapacityID: number;
  SuiteCategory: string;
  SuiteCategoryCod: string;
  SuiteCategoryID: number;
  SuiteNo: string;
  SuiteLabel: string;
  VoyageID: number;
  VoyageIDNext: number;
  VoyageIDPrev: number;
  VoyageName: string;
  VoyageNumber: string;
  PaymentDueDate: Date;
  FullBookingSchedulingFlag: boolean;
  Amount: number;
  IsFinalPayment: boolean;
  EcmData: Array<IReservationApiPackageEcmData>;
  PortCharge: number;
  DestinationName: string;
  SubDestinationID: number;
  SubDestinationName: string;
  DeckPlanStartFrom: Date;
  HasPromo: ReservationApiBoolean;
  NetFareAvailable: string;
  HasBundle: string
  PromoPrice: IResProductApiPromoPrice[]
  PriceTypes?:any[]
  PromoStartDate?:DateTime
  PromoEndDate?:DateTime
  Ada?: string;
}

export class ReservationCruiseItinerary extends SafeBaseObject {

  public arrivalTime: DateTime | undefined;
  public cityCod = '';
  public cityID = 0;
  public cityName = '';
  public citySearchable = false;
  public country = '';
  public countryCodISO2 = '';
  public countryCodISO3 = '';
  public countryID = '';
  public day = 0;
  public debarkFrom: DateTime | undefined;
  public debarkTo: DateTime | undefined;
  public departTime: DateTime | undefined;
  public destinationName = '';
  public embarkFrom: DateTime | undefined;
  public embarkTo: DateTime | undefined;
  public hospitalityInfo = '';
  public isCity = false;
  public itineraryDate: DateTime | undefined;
  public itnID = 0;
  public latitude = 0;
  public longitude = 0;
  public pier = '';
  public pierID = 0;
  public portAgent = '';
  public portAgentPhone = '';
  public portCod = '';
  public pos = 0;
  public shuttleInfo = '';
  public staffInfo = '';
  public voyageID = 0;
  public dockAnchor = '';
  public isOvernight = false;
  isAnchor = false
  public itineraryTypeID = 0;
  public itineraryTypeName = '';
  public meals:Array<IReservationPackageMeal> = [];
  public serviceOptions:Array<ReservationProductServiceOptions> = [];

  constructor(data: IReservationApiCruiseItinerary) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
      // const coreCacheService = AppModule.injector.get(CoreCacheService);
      // coreCacheService.getCachedData<IReservationPackageMeal>('PackageMealTypes').then(mealType=>{
      //   const allMealType = mealType;
      //   this.meals = data.MealID.split(',').map(item=>{
      //     return {Cod: item, Desc: (allMealType.find(meal=>+meal.Cod === +item)?.Desc ?? '')};
      //   })
      // });
    }
  }

  override updateData(data: IReservationApiCruiseItinerary): void {
    this.addDateTimeProperty("arrivalTime", data, "ArrivalTime");
    this.addBooleanProperty("citySearchable", data, "CitySearchable");
    this.addDateTimeProperty("debarkFrom", data, "DebarkFrom");
    this.addDateTimeProperty("debarkTo", data, "DebarkTo");
    this.addDateTimeProperty("departTime", data, "DepartTime");
    this.addDateTimeProperty("embarkFrom", data, "EmbarkFrom");
    this.addDateTimeProperty("embarkTo", data, "EmbarkTo");
    this.addBooleanProperty("isCity", data, "IsCity");
    this.addDateTimeProperty("itineraryDate", data, "ItineraryDate");
    this.addBooleanProperty("isOvernight", data, "IsOvernight");
  }
}

export interface IReservationApiCruiseItinerary {
  ArrivalTime: Date;
  CityCod: string;
  CityID: number;
  CityName: string;
  CitySearchable: boolean;
  Country: string;
  CountryCodISO2: string;
  CountryCodISO3: string;
  CountryID: string;
  Day: number;
  DebarkFrom: Date;
  DebarkTo: Date;
  DepartTime: Date;
  DestinationName: string;
  EmbarkFrom: Date;
  EmbarkTo: Date;
  HospitalityInfo: string;
  IsCity: boolean;
  ItineraryDate: Date;
  ItnID: number;
  Latitude: number;
  Longitude: number;
  Pier: string;
  PierID: number;
  PortAgent: string;
  PortAgentPhone: string;
  PortCod: string;
  Pos: number;
  ShuttleInfo: string;
  StaffInfo: string;
  VoyageID: number;
  DockAnchor: string;
  IsOvernight: string;
  MealID:string;
}

class ReservationCruiseFeature extends BaseObject {
  public featureDesc = '';
  public featureId = 0;
  public featureListId = 0;
  public status = '';
  public voyageId = 0;
  constructor(data: IReservationApiCruiseFeature) {
    super(data);
  }

  override updateData(data: IReservationApiCruiseFeature): void {
    this.addProperty("featureDesc", data, "FeatureDesc");
    this.addProperty("featureId", data, "FeatureID");
    this.addProperty("featureListId", data, "FeatureListID");
    this.addProperty("status", data, "Status");
    this.addProperty("voyageId", data, "VoyageID");
  }
}

export interface IReservationApiCruiseFeature {
  FeatureDesc: string;
  FeatureID: number;
  FeatureListID: number;
  Status: string;
  VoyageID: number;
}

export interface IReservationApiCruiseDetail {
  VoyIdPrev: number;
  VoyageId: number;
  VoyIdNext: number;
}

class ReservationCruiseService extends BaseObject {
  public optionName = '';
  public serviceIconCode = '';
  public serviceTypeName = '';
  public voyageID = 0;

  constructor(data: IReservationApiCruiseService) {
    super(data);
  }

  override updateData(data: IReservationApiCruiseService): void {
    this.addProperty("optionName", data, "OptionName");
    this.addProperty("serviceTypeName", data, "ServiceTypeName");
    this.addProperty("voyageID", data, "VoyageID");
  }
}

export interface IReservationApiCruiseService {
  OptionName: string;
  ServiceTypeName: string;
  VoyageID: number;
}

export interface IReservationApiDeleteCruiseParams {
  BkgID?: number;
  SectionID?: number;
  VoyageID?: number;
  SuiteNum?: string;
  RoomID?: number;
  KeySuiteID?: number;
}

export class ResSuitePriceCategory extends SafeBaseObject {
  priceCategoryID: number = 0;
  suiteCategoryCod: string = '';
  priceCategory: string = '';
  sellingPrice: number = 0;
  formattedSellingPrice: string = '';
  voyageID: number = 0;
  guestCod: string = '';

  constructor(data: IReservationSuitePriceCategory) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationSuitePriceCategory): void {
    this.addMangledProperty(data)
  }
}

export interface IReservationSuitePriceCategory {
  PriceCategoryID: number;
  SuiteCategoryCod: string;
  PriceCategory: string;
  SellingPrice: number;
  VoyageID: number;
  GuestCod: string;
}

export interface IReservationApiUpdateSuiteData {
  BkgID: number;
  SectionID: number;
  FixPrice: IReservationApiFixPriceCategory[];
  UpSelling: IReservationApiUpSellingCategory[];
}

export interface IReservationApiFixPriceCategory {
  VoyageID: number;
  GuestID: number;
  PriceCategoryID: number;
  FixedPrice?: number;
  GuestTypeID: number;
}

export interface IReservationApiUpSellingCategory {
  VoyageID: number;
  GuestID: number;
  OriginalSuiteCategoryID?: number;
  OriginalSuiteCategoryValue?: number;
  UpSellingValue?: string;
}

/**
 * Model of voyage returned by [Res].[dbo].[Voyage_Detail]
 * used in suite-setup page
 *
 * @export
 * @class ReservationCruiseLight
 * @extends {SafeBaseObject}
 */
export class ReservationCruiseLight extends SafeBaseObject {

  voyageID: number = 0;
  year: number = 0;
  ship: string = '';
  shipCod: string = '';
  voyageNumber: string = '';
  cruiseLine: string = '';
  duration: number = 0;
  destinationName: string = '';
  debarkDate?: DateTime;
  debarkCity: string = '';
  embarkDate?: DateTime;
  embarkCity: string = '';
  voyageClass: string = '';
  voyIDPrev: number = 0;
  voyIDNext: number = 0;
  priceTypeID: number = 3;
  isOverNight = false
  isAnchor = false

  internalType: 'voyage' = 'voyage';
  packageID: undefined = undefined;
  services: [] = [];

  ecmData: ReservationPackageEcmData = new ReservationPackageEcmData();
  itineraries: ReservationCruiseItinerary[] = [];
  matchingPorts: ResCruiseMatchingPort[] = [];

  constructor(data: IReservationApiCruiseLight) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }

  override updateData(data: IReservationApiCruiseLight): void {
    this.addDateTimeProperty("embarkDate", data, "EmbarkDate");
    this.addDateTimeProperty("debarkDate", data, "DebarkDate");

    if(data?.MatchingPorts) this.matchingPorts = data.MatchingPorts.map((port) => new ResCruiseMatchingPort(port));
  }
  get hasMediaData() {
    return this.ecmData.loaded
  }
  get hasImages() {
    return this.ecmData.images.length > 0
  }
  get hasMap() {
    return !!this.ecmData.imageMapSafe
  }
  get mapUrl() {
    return this.ecmData.imageMapSafe
  }
  get imagesUrl() {
    return this.ecmData.imagesSafe
  }
  get hasHtmlDescription() {
    return !!this.ecmData.descriptionSafe
  }
  get descriptionHtml() {
    return this.ecmData.descriptionSafe
  }
  loadItinerary(reservationApiService: ReservationApiService): void {
    if (this.itineraries.length === 0) {
      reservationApiService.getVoyageItinerary(this.voyageID).subscribe(itineraries => this.itineraries = itineraries);
    }
  }

  loadEcmData(reservationApiService: ReservationApiService): Promise<ReservationPackageEcmData> {
    if (this.ecmData.loaded) return Promise.resolve(this.ecmData);

    return lastValueFrom(reservationApiService.getVoyageEcmData(this.voyageID).pipe(
      map<Array<IReservationApiPackageEcmData>, ReservationPackageEcmData>((ecmData) => this.ecmData = ReservationPackageEcmData.mapEcmData(ecmData))
    ));
  }

  displayName(): string {
    return this.voyageNumber ?? `${this.voyageID}` ?? '';
  }
  displayBrand(): string {
    return this.ship;
  }
  getStartDate(): DateTime | undefined {
    return this.embarkDate;
  }
  getEndDate(): DateTime | undefined {
    return this.debarkDate;
  }
}

export interface IReservationApiCruiseLight {
  VoyageID: number;
  Year: number;
  Ship: string;
  ShipCod: string;
  VoyageNumber: string;
  CruiseLine: string;
  Duration: number;
  DestinationName: string;
  DebarkDate: string;
  DebarkCity: string;
  EmbarkDate: string;
  EmbarkCity: string;
  VoyageClass: string;
  VoyIDPrev: number;
  VoyIDNext: number;
  PriceTypeID: number;
  MatchingPorts: IResApiCruiseMatchingPort[];
}

export class ResCruiseMatchingPort extends SafeBaseObject {
  cityID: number = 0;
  cityName: string = '';
  itineraryDate?: DateTime;
  formattedItineraryDate: string = '';
  voyageID: number = 0;

  constructor(data: IResApiCruiseMatchingPort) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IResApiCruiseMatchingPort): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty("itineraryDate", data, "ItineraryDate");
    this.formattedItineraryDate = this.itineraryDate?.toFormat('dd LLL yyyy') ?? '';
  }
}

export interface IResApiCruiseMatchingPort {
  CityID: number;
  CityName: string;
  ItineraryDate: string;
  VoyageID: number;
}

export class ReservationCruiseSuiteProfitability extends SafeBaseObject {

  bkgStatus: string = '';
  bkgID: number = 0;
  guestNr: number = 0;
  duration: number = 0;
  paxDays: number = 0;
  suiteUpgrade: boolean = false;
  priceCategory: string = '';
  displaySequence: number = 0;
  guestType: string = '';
  totalBookingAmount: number = 0;
  totalReceipt: number = 0;
  totalAmountDue: number = 0;
  cruiseCharge: number = 0;
  destFees: number = 0;
  discount: number = 0;
  otherCharges: number = 0;
  totalCommission: number = 0;
  netPerDiem: number = 0;

  constructor(data: IReservationApiCruiseSuiteProfitability) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }

  override updateData(data: IReservationApiCruiseSuiteProfitability): void {
    this.addBooleanProperty("suiteUpgrade", data, "SuiteUpgrade");
    this.addProperty("bkgID", data, "BKGID");
  }
}


export interface IReservationApiCruiseSuiteProfitability {
  BkgStatus: string;
  BKGID: number;
  GuestNr: number;
  Duration: number;
  PaxDays: number;
  SuiteUpgrade: ReservationApiBoolean;
  PriceCategory: string;
  DisplaySequence: number;
  GuestType: string;
  TotalBookingAmount: number;
  TotalReceipt: number;
  TotalAmountDue: number;
  CruiseCharge: number;
  DestFees: number;
  Discount: number;
  OtherCharges: number;
  TotalCommission: number;
  NetPerDiem: number;
}
export interface IResGetCabinOccupancyParams {
  Year: number
  Month: number
  ShipIds?: string
  CruiseLineIds?: string
}

export class ReservationCabinOccupancy extends SafeBaseObject {
  shipID = 0
  ship = ''
  vmVoyageID = 0
  vmEmbarkDate = DateTime.invalid('empty')
  vmDebarkDate = DateTime.invalid('empty')
  voyageID = 0
  voyageName = ''
  voyageNumber = ''
  embarkDate = DateTime.invalid('empty')
  debarkDate = DateTime.invalid('empty')
  suiteNo = ''
  suiteStatus = ''
  suiteCategory = ''
  suiteCapacity = ''
  suiteCapacityNum = 0
  deckNumber = 0
  deckName = ''
  bkgID = 0
  ownerType = ''
  agency = ''
  lastName = ''
  firstName = ''
  bkgStatus = ''
  priceTypeID = 0
  paymentFlag = ''
  isMaster = false

  constructor(data: any) {
    super();
    if (!data) return

    super.updateData(data);
    this.updateData(data);

  }

  override updateData(data: any): void {
    if (!data) return

    this.addProperty('vmVoyageID',data,'VMVoyageID')
    this.addDateTimeProperty('vmEmbarkDate',data,'VMEmbarkDate')
    this.addDateTimeProperty('vmDebarkDate',data,'VMDebarkDate')

    this.isMaster = this.vmVoyageID == this.voyageID
  }
}
export class ReservationCabinOccupancyByMonth {
  ships:ReservationCabinOccupancyShip[] = []
  year = 0
  month = 0
  viewName = ''
  startDate = DateTime.invalid('empty')
  endDate = DateTime.invalid('empty')
  daysOfMonth = 0
}
export class ReservationCabinOccupancyVoyage {
  id = 0
  name = ''
  title = ''
  starDay = 0
  endDay = 0
  days = 0
  cols = 0
  startCollision = false
  endCollision = false
}
export class ReservationCabinOccupancyShip {
  shipID = 0
  ship = ''
  suites:string[] = []
  voyageIds:number[] = []
  voyages:ReservationCabinOccupancyVoyage[] = []
  voyagesMaster:ReservationCabinOccupancyVoyage[] = []
  cabinStatus:ReservationCabinOccupancyStatus[] = []
  wlStatus:ReservationCabinOccupancyStatus[] = []
  constructor(shipID:number,ship:string) {
    this.ship = ship
    this.shipID = shipID
  }
}
export class ReservationCabinOccupancyStatus {
  suiteNo = ''
  suiteCategory = ''
  suiteCapacity = ''
  suiteCapacityNum = 0
  deckNumber = 0
  deckName = ''
  voyageID = 0
  voyageNumber = ''
  voyageName = ''
  vmVoyageID = 0
  vmEmbarkDate = DateTime.invalid('empty')
  vmDebarkDate = DateTime.invalid('empty')
  status = ''
  statusFrom = DateTime.invalid('empty')
  statusTo = DateTime.invalid('empty')
  statusDays = 0
  guests:BookingGuest[] = []
  bkgID = 0
  ownerType = ''
  agency = ''
  bkgStatus = ''
  priceTypeID = 0
  paymentFlag = ''
  isMaster = false
  get key() {
    return `${this.suiteNo}-${this.voyageID}`
  }
  static fromOccupancy(data:ReservationCabinOccupancy,month:number,daysOfMonth:number) {

    const cabinOccupancy = new ReservationCabinOccupancyStatus()

    const startDay = data.embarkDate.month === month ? data.embarkDate.day : 1
    const endDay = data.debarkDate.month === month ? data.debarkDate.day : daysOfMonth

    cabinOccupancy.suiteNo 				  = data.suiteNo
    cabinOccupancy.voyageID         = data.voyageID
    cabinOccupancy.voyageName       = data.voyageName
    cabinOccupancy.voyageNumber      = data.voyageNumber

    cabinOccupancy.vmVoyageID       = data.vmVoyageID
    cabinOccupancy.vmEmbarkDate     = data.vmEmbarkDate
    cabinOccupancy.vmDebarkDate     = data.vmDebarkDate

    cabinOccupancy.suiteCategory 		= data.suiteCategory
    cabinOccupancy.suiteCapacity 		= data.suiteCapacity
    cabinOccupancy.suiteCapacityNum = data.suiteCapacityNum
    cabinOccupancy.deckNumber 			= data.deckNumber
    cabinOccupancy.deckName 			  = data.deckName
    cabinOccupancy.status 				  = data.suiteStatus
    cabinOccupancy.statusFrom 			= data.embarkDate
    cabinOccupancy.statusTo 			  = data.debarkDate
    cabinOccupancy.bkgID 				    = data.bkgID
    cabinOccupancy.ownerType 			  = data.ownerType
    cabinOccupancy.agency 				  = data.agency
    cabinOccupancy.bkgStatus 			  = data.bkgStatus
    cabinOccupancy.priceTypeID 			= data.priceTypeID
    cabinOccupancy.paymentFlag 			= data.paymentFlag
    cabinOccupancy.isMaster 			  = data.isMaster

    cabinOccupancy.statusDays       = endDay - startDay + 1

    const guest = new BookingGuest()
    guest.firstName = data.firstName
    guest.lastName = data.lastName
    cabinOccupancy.guests.push(guest)

    return cabinOccupancy
  }
}
