import { HttpResponse } from '@angular/common/http';
import { AppModule } from '@app/app.module';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, map, Subject } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { EidosConfigService } from '@app/core/config/eidos-config.service';
import { EidosIconType } from '@app/core/models/core-constant.model';
import { DynamicApiResponse, IDynamicApiErrorResponse, IDynamicApiResponse } from '@app/core/models/dynamic-api-response.model';
import { CoreCacheService } from '@app/core/services/core-cache.service';
import { CoreFormatService } from '@app/core/services/core-format.service';
import { EidosApiService } from '@app/core/services/eidos-api.service';
import { CoreUtilityDialog } from '@app/core/services/eidos-utility.service';
import { SpinnerOverlayService } from '@app/core/services/spinner-overlay.service';
import { ReservationEditSupplementAction } from '../components/res-bkg-edit-supplement/res-bkg-edit-supplement.component';
import {
  BookingSectionResCard,
  IResCardLineOptions,
  IResCardLineOptionsActions,
  IResCardLineOptionsHyperlink,
  ResCard,
  ResCardLine,
  ResCardLineOptionType,
} from '../components/res-card/res-card.model';
import { ReservationApiService } from '../services/reservation-api.service';
import { ReservationService } from '../services/reservation.service';
import { ReservationBookingLock } from './res-booking-lock.model';
import {
  BookingGuest,
  BookingJourneySupplement,
  BookingSection,
  BookingSupplement,
  IReservationApiBooking,
  ResBooking,
  ResBookingFinancialRecap,
  ResBookingGifFlag,
  ResFinancialSplit,
} from './res-booking.model';
import { IReservationCompany } from './res-cached-data.models';
import { ResInsuranceAction } from './res-insurance.model ';
import {
  ReservationAction,
  ReservationActionBooking,
} from './reservation-permission.model';
import { ResFinancialSplitDialogComponent } from '../components/res-financial-split-dialog/res-financial-split-dialog.component';

export type ReservationToBeCheckedActionBooking = 'ChangeVoyage' | '';

export interface IResBookingActionCommand {
  action: ReservationActionBooking;
  sectionID?: number;
  actionParams: any[];
}

export type ResRequestSupplementActionData =  {supplement:BookingJourneySupplement|BookingSupplement,action:number}

export class ResBookingUtility {
  static readonly ACCOMODATION_SERVICE_TYPE_ID = 3;
  static readonly ACTION_ADDOPTION_PRE = 1;
  static readonly ACTION_ADDOPTION_POST = 2;

  requestSupplementAction = new Subject<ResRequestSupplementActionData>()
  addOptionFilters = new BehaviorSubject<any>({})


  /**
   * Reservation actions in booking to be check by API
   *
   * @private
   * @static
   * @memberof ResBookingUtility
   */
  private static readonly RESERVATION_ACTION_BOOKING_TBCHECKED = [
    ReservationActionBooking.ChangeVoyageBookingVersion,
  ];
  /**
   * Is anon access, the bkg:
   * - is retrieved by GUID probably in checkout page
   *
   * @memberof ResBookingUtility
   */
  public bookingRetrievedInAnonymous = false;
  /**
   * Is read only access
   * - User has read only role
   *
   * @memberof ResBookingUtility
   */
  private _bookingRetrievedInReadOnly = false;
  set bookingRetrievedInReadOnly(val: boolean) {
    this._bookingRetrievedInReadOnly = val;
    this.bookingLock.bookingRetrievedInReadOnly = val;
  }
  get bookingRetrievedInReadOnly(): boolean {
    return this._bookingRetrievedInReadOnly;
  }
  /**
   * Current booking
   *
   * @type {BehaviorSubject<ResBooking>}
   * @memberof ReservationService
   */
  public current: BehaviorSubject<ResBooking> = new BehaviorSubject<ResBooking>(
    new ResBooking()
  );
  /**
   * Current selected section ID
   *
   * @type {BehaviorSubject<number>}
   * @memberof ResBookingUtility
   */
  public selectedSectionId: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);
  /**
   * Current selected section
   *
   * @readonly
   * @type {BookingSection}
   * @memberof ResBookingUtility
   */
  public get selectedSection(): BookingSection {
    return this.current.value.getSection(this.selectedSectionId.value);
  }
  /**
   * Current booking Lock
   *
   * @type {ReservationBookingLock}
   * @memberof ResBookingUtility
   */
  public bookingLock: ReservationBookingLock;
  /**
   * Reservation API service reference
   *
   * @private
   * @type {ReservationApiService}
   * @memberof ResBookingUtility
   */
  private reservationApiService: ReservationApiService;
  /**
   * Core API service reference
   *
   * @private
   * @type {EidosApiService}
   * @memberof ResBookingUtility
   */
  private coreApiService: EidosApiService;

  /**
   * Booking action command subject
   *
   * @type {Subject<IResBookingActionCommand>}
   * @memberof ResBookingUtility
   */
  actionCommand$: Subject<IResBookingActionCommand> =
    new Subject<IResBookingActionCommand>();
  /**
   * Previous booking action checks dict
   *
   * @type {{ [key in ReservationToBeCheckedActionBooking]?: IDynamicApiErrorResponse[] }}
   * @memberof ResBookingUtility
   */
  canDoActionInBooking: {
    [key in ReservationToBeCheckedActionBooking]?: IDynamicApiErrorResponse[];
  } = {};

  openInsuranceCommand$: Subject<{
    guest?: BookingGuest;
    action?: ResInsuranceAction;
  }> = new Subject<{ guest?: BookingGuest; action?: ResInsuranceAction }>();

  editSupplementsCommand$: Subject<{
    supplement: BookingSupplement;
    action: ReservationEditSupplementAction;
  }> = new Subject<{
    supplement: BookingSupplement;
    action: ReservationEditSupplementAction;
  }>();

  ecmDataChanged$: Subject<object> = new Subject<{}>();

  private coreCacheService: CoreCacheService
  private dialog: MatDialog
  initialCommissionValue?: number

  constructor(
    public dialogs: CoreUtilityDialog,
    public resService: ReservationService,
  ) {
    this.reservationApiService = AppModule.injector.get(ReservationApiService);
    this.coreApiService = AppModule.injector.get(EidosApiService);
    this.coreCacheService= AppModule.injector.get(CoreCacheService);
    this.dialog= AppModule.injector.get(MatDialog);

    this.bookingLock = new ReservationBookingLock(
      this.reservationApiService,
      dialogs,
      resService
    );

    // Reset action checks
    this.current.subscribe(() => (this.canDoActionInBooking = {}));
  }

  /**
   * Has current booking flag getter
   *
   * @readonly
   * @type {boolean}
   * @memberof ResBookingUtility
   */
  public get hasCurrentBooking(): boolean {
    return this.current.getValue().isValid();
  }
  /**
   * Load booking by GUID
   * Does not require auth
   *
   * @param {string} guid
   * @return {*}  {Promise<boolean>}
   * @memberof ResBookingUtility
   */
  public loadBkgByGuid(guid: string): Promise<boolean> {
    this.bookingRetrievedInAnonymous = true;
    this.bookingLock.bookingRetrievedInAnonymous = true;

    return new Promise<boolean>((resolve) => {
      this.coreApiService
        .getPublicAPI('v1/booking/SelectByGuid', 'POST', { Guid: guid })
        .pipe(
          map<HttpResponse<DynamicApiResponse>, DynamicApiResponse>(
            (response) => new DynamicApiResponse(response)
          )
        )
        .subscribe({
          next: (data: DynamicApiResponse) => {
            const booking = new ResBooking(data.body as IReservationApiBooking);
            this.current.next(booking);
            resolve(true);
          },
          error: () => {
            resolve(false);
          },
          complete: () => {
            resolve(true);
          },
        });
    });
  }
  /**
   * Load booking by ID
   * Requires auth
   *
   * @param {number} [bkgId]
   * @return {*}  {Promise<boolean>}
   * @memberof ResBookingUtility
   */
  public loadBkg(bkgId?: number): Promise<boolean> {
    const _bkgId = bkgId ?? this.current.getValue().bkgID;
    if (!_bkgId || _bkgId === ResBooking.INVALID_BOOKING) {
      return new Promise<boolean>((resolve) => resolve(false))
    }

    //read promo booking
    this.resService.promoUtility.getBookingPromo(_bkgId);

    return new Promise<boolean>((resolve) => {

      combineLatest([
        this.reservationApiService.getBooking({ BkgID: _bkgId })
        , this.coreCacheService.getCachedData<IReservationCompany>('Companies')
        , this.resService.getAvailableCompanies()
      ])
      .subscribe({
        next: ([bkg,companies,availableCompanies]) => {

          if (this.bookingRetrievedInAnonymous) {
            this.current.next(bkg);
            resolve(true)
            return
          }
          if(!availableCompanies.find(c=>c.code === bkg.companyCode.trim())) {
            console.log('BKG - INVALID ACCESS')
            // resolve(false);
            // this.resService.utilityDialogs.alert({
            //   message: "I'm sorry, but you don't have access to this page",
            //   title: `INVALID ACCESS`,
            //   size: '380px',
            // }).then(_=>this.resService.goToReservationHome());
            // return
          }

          this.resService.updateEnvWithBooking(bkg).then(_=>{
            let isCurrentlyLocked = this.bookingLock.isLocked(_bkgId);
            if (!isCurrentlyLocked) {
              this.bookingLock.lock(_bkgId);
            }
            this.current.next(bkg);
            //@TODO CHECK USER HAS VISIBILITY ON THIS COMPANY
            //this.coreUtilityService.getAvailableCompanies()
            const curCompany = this.resService.currentCompany.getValue()
            if(!this.resService.checkCompanyAlias(curCompany?.CompanyID ?? 0,bkg.companyID)) {
              let company = companies.find(c=>c.CompanyID===bkg.companyID)
              if(!company) this.resService.allCompanies.find(c => this.resService.checkCompanyAlias(c.CompanyID,bkg.companyID))
              this.resService.setCompany(company)
            }
          });

          // if (this.initialCommissionValue && this.initialCommissionValue !== bkg.financialRecap[0]?.commission && bkg.financialRecap[0]?.commissionPaid! > 0){
          //   this.resService.utilityDialogs.alert({
          //     message: 'The Commission value has been updated.',
          //     title: `Attention`,
          //   }).then(_ => {})
          // }
          // this.initialCommissionValue = bkg.financialRecap[0]?.commission ?? 0
          resolve(true)
        },
        error: () => {
          resolve(false);
        },
        complete: () => {
          resolve(true);
          const bkg = this.current.getValue()
          if(bkg.isValid() && bkg.jonesActAlert) {
            this.resService.warningJonesAct()
          }
        },
      });
    });
  }

  /**
   * Reload current booking
   * Requires auth
   *
   * @return {*}
   * @memberof ResBookingUtility
   */
  public reloadCurrentBkg() {
    return this.loadBkg();
  }
  public sectionCurrentView?: number

  /**
   * Reload current booking financials
   * Requires auth
   *
   * @return {*}
   * @memberof ResBookingUtility
   */
  public reloadCurrentBkgFinancials() {
    const bkg = this.current.getValue();

    return new Promise<boolean>((resolve) => {
      if (bkg.isValid()) {
        this.reservationApiService.getBookingFinancials(bkg.bkgID).subscribe({
          next: (financials: Array<ResBookingFinancialRecap>) => {
            bkg.financialRecap = financials;
            this.current.next(bkg);
          },
          error: () => {
            resolve(false);
          },
          complete: () => {
            resolve(true);
          },
        });
      } else {
        resolve(false);
      }
    });
  }
  /**
   * Checks if current booking is R/O
   *
   * @param {ResBooking} [bkg]
   * @return {*}  {boolean}
   * @memberof ResBookingUtility
   */
  public isCurrentBkgReadOnly(bkg?: ResBooking): boolean {
    if (!bkg) {
      bkg = this.current.getValue();
    }

    if (bkg.isDeleted()) return true;

    return false;
  }
  /**
   * Checks if currently locked booking has bkgID parameter as ID and it R/O
   *
   * @param {number} bkgID
   * @return {*}  {boolean}
   * @memberof ResBookingUtility
   */
  public isCurrentBkgNetFare(bkg?: ResBooking): boolean {
    return bkg?.isNetFare ?? this.current.getValue().isNetFare;
  }
  /**
   * Checks if current booking is locked
   *
   * @param {ResBooking} [bkg]
   * @return {*}  {boolean}
   * @memberof ResBookingUtility
   */
  public isCurrentBkgLocked(bkg?: ResBooking): boolean {
    if (!bkg) {
      bkg = this.current.getValue();
    }
    return bkg.isValid() && this.bookingLock.isLocked(bkg.bkgID);
  }
  /**
   * Checks if currently locked booking has bkgID parameter as ID and it R/O
   *
   * @param {number} bkgID
   * @return {*}  {boolean}
   * @memberof ResBookingUtility
   */
  public isBkgReadOnlyByID(bkgID: number): boolean {
    return (
      bkgID !== ResBooking.INVALID_BOOKING && !this.bookingLock.isLocked(bkgID)
    );
  }
  /**
   * Checks if currently locked booking has bkgID parameter as ID
   *
   * @param {number} bkgID
   * @return {*}  {boolean}
   * @memberof ResBookingUtility
   */
  public isBkgLockedByID(bkgID: number): boolean {
    return (
      bkgID !== ResBooking.INVALID_BOOKING && this.bookingLock.isLocked(bkgID)
    );
  }
  /**
   * Checks if can perform an action in current booking
   *
   * @param {ReservationActionBooking} action
   * @param {number} [sectionID]
   * @param {number} [bkgID]
   * @return {*}  {Promise<boolean>}
   * @memberof ResBookingUtility
   */
  checkBkgAction(
    action: ReservationActionBooking,
    sectionID?: number,
    bkgID?: number
  ): Promise<IDynamicApiErrorResponse[]> {
    return new Promise<IDynamicApiErrorResponse[]>((resolve) => {
      if (!bkgID) {
        bkgID = this.current.getValue().bkgID;
      }

      if (bkgID !== ResBooking.INVALID_BOOKING) {
        let mappedAction: ReservationToBeCheckedActionBooking = '';

        switch (action) {
          case ReservationActionBooking.ChangeVoyageBookingVersion:
            mappedAction = 'ChangeVoyage';
            break;
          default:
            break;
        }

        if (
          !_.isEmpty(mappedAction) &&
          mappedAction in this.canDoActionInBooking
        ) {
          resolve(this.canDoActionInBooking[mappedAction]!);
        } else {
          const spinnerOverlayService = AppModule.injector.get(
            SpinnerOverlayService
          );

          spinnerOverlayService.show();

          this.reservationApiService
            .checkBookingAction({
              BkgID: bkgID,
              Action: action,
              VersionID: sectionID,
            })
            .subscribe({
              next: () => {
                spinnerOverlayService.hide();

                this.canDoActionInBooking[mappedAction] = [];
                resolve([]);
              },
              error: (error: IDynamicApiResponse) => {
                spinnerOverlayService.hide();

                this.canDoActionInBooking[mappedAction] = error.Errors;
                this.resService.utilityDialogs.error({
                  message: '',
                  error: error.Errors.map((e: any) => e.ErrorDesc),
                  title: `ERROR IN BOOKING ACTION`,
                  size: '380px',
                });
                resolve(error.Errors);
              },
            });
        }
      } else {
        this.resService.utilityDialogs.error({
          message: '',
          error: ['No current booking selected'],
          title: `ERROR IN BOOKING ACTION`,
          size: '380px',
        });
        resolve([
          {
            ErrorCod: '',
            ErrorDesc: 'No current booking',
          },
        ]);
      }
    });
  }
  /**
   * Eventually checks if can perform an action in current booking than do it
   *
   * @param {ReservationActionBooking} action
   * @param {number} [sectionID]
   * @param {number} [bkgID]
   * @return {*}  {Promise<IDynamicApiErrorResponse[]>}
   * @memberof ResBookingUtility
   */
  launchBkgAction(
    action: ReservationActionBooking,
    sectionID?: number,
    bkgID?: number,
    ...actionParams: any[]
  ): Promise<IDynamicApiErrorResponse[]> {
    return new Promise<IDynamicApiErrorResponse[]>(async (resolve) => {
      if (!bkgID) {
        bkgID = this.current.getValue().bkgID;
      }

      if (
        ResBookingUtility.RESERVATION_ACTION_BOOKING_TBCHECKED.includes(action)
      ) {
        const checkBkgAction = await this.checkBkgAction(
          action,
          sectionID,
          bkgID
        );
        if (checkBkgAction.length > 0) {
          resolve(checkBkgAction);
          return;
        }
      }

      this.actionCommand$.next({
        action: action,
        sectionID: sectionID,
        actionParams: actionParams,
      });

      resolve([]);
    });
  }
  /**
   * Leave current booking handler
   * Includes
   * - Booking unlocking
   * - Lock hiding
   * - Current booking reset
   *
   * @memberof ResBookingUtility
   */
  public leaveCurrentBooking() {
    this.bookingLock.hide();
    this.current.next(new ResBooking());
  }

  financialSplit : ResFinancialSplit[] = [];

  viewFinacialDetails(line:any) {
    let section = 'Paid'

    if(line.caption.toLowerCase().includes('charge')) {
      section = 'Charge'
    }

    const bkg = this.current.getValue();

    this.dialog.open(ResFinancialSplitDialogComponent, {
      width: '700px',
      data: {
        section:  section,
        bkgID: bkg.bkgID
      }
    });

  }
  /**
   * Computes sidebar cards for a light booking
   *
   * @static
   * @param {ResBooking} b
   * @param {CoreFormatService} coreFormatService
   * @return {*}  {ResCard[]}
   * @memberof ResBookingUtility
   */
  public static loadCardsForLightBooking(
    b: ResBooking,
    coreFormatService: CoreFormatService,
    eidosConfigService: EidosConfigService,
    resService: ReservationService
  ): ResCard[] {
    const cards: ResCard[] = [];
    let card = new ResCard();
    cards.push(card);

    const iconFinacialDetails = {
      iconType: EidosIconType.Awesome,
      iconCode: "fa-plus-circle",
      iconSize: 0.6,
    }
    const finacialActions:IResCardLineOptionsActions[] = [
      {
        icon:iconFinacialDetails,
        callback:resService.bookingUtility.viewFinacialDetails
      },
    ]

    if (!b.isOpenDeposit) {
      card.title = 'GENERAL INFO';

      card.lines.push(new ResCardLine('Status', b.bkgStatus ?? ''));
      // card.lines.push(new ResCardLine('Type', b.bkgType ?? ''));
      card.lines.push(new ResCardLine('Company', b.companyName ?? ''));
      card.lines.push(new ResCardLine('Price Type', b.priceTypeName ?? ''));
      card.lines.push(new ResCardLine('Currency', b.bkgCurrency ?? ''));

      const dateStartFormatted: string = !!b.travelStartDate
        ? b.travelStartDate.toFormat(coreFormatService.DateFmtWithMonthName())
        : '';
      const dateEndFormatted: string = !!b.travelEndDate
        ? b.travelEndDate.toFormat(coreFormatService.DateFmtWithMonthName())
        : '';

      card.lines.push(
        new ResCardLine('Date', `${dateStartFormatted} - ${dateEndFormatted}`)
      );

      b.sections.forEach((s) => {
        card.lines.push(
          new ResCardLine('Guest N.', s.sectionID.toString(), {
            status: s.sectionID.toString(),
          } as IResCardLineOptions)
        );
      });

      if (b.grpID ?? 0 > 0) {
        const hyperlink = {
          url: eidosConfigService.getLinks('groupmaster')?.template,
          target: '_blank',
          parameters: { id: b.grpID },
        } as IResCardLineOptionsHyperlink;
        card.lines.push(
          new ResCardLine(
            'Group',
            b.grpName.length > 0 ? `${b.grpName} - (${b.grpID})` : `${b.grpID}`,
            { hyperlink: hyperlink } as IResCardLineOptions
          )
        );
      }

      card.lines.push(new ResCardLine('Note', b.note));

      cards.push(card);

      card = new ResCard();
    }

    card.title = 'BOOKING OWNER';

    if (b.owner) {
      if (b.owner.isAgent()) {
        const hyperlink = {
          url: eidosConfigService.getLinks('entitymaster')?.template,
          target: '_blank',
          parameters: { entity: 'agency', id: b.owner.agencyID },
        } as IResCardLineOptionsHyperlink;

        card.lines.push(
          new ResCardLine('Agency', b.owner.displayName(), {
            hyperlink: hyperlink,
          } as IResCardLineOptions)
        );
        // Open point Carlo 10.3.2023
        // Add IATA and Agency to the booking owner screen
        if (b.owner.agencyIataCod)
          card.lines.push(
            new ResCardLine('IATA Code', b.owner.agencyIataCod ?? '')
          );

        card.lines.push(
          new ResCardLine('Contact', b.owner.displayContactName())
        );
      } else {
        card.lines.push(new ResCardLine('Direct', b.owner.displayName()));
      }

      let address = b.owner.addressToDisplay;
      if (b.owner.address2) {
        address += `${!!b.owner.addressToDisplay ? ' - ' : ''} ${!!b.owner
          .address2}`;
      }
      card.lines.push(new ResCardLine('Address', address ?? ''));
      card.lines.push(new ResCardLine('Country', b.owner.country ?? ''));
      card.lines.push(new ResCardLine('Email', b.owner.email ?? ''));
      card.lines.push(new ResCardLine('Phone', b.owner.phone ?? ''));

      if (b.isOpenDeposit && b.owner?.displayGuest) {
        card.lines.push(new ResCardLine('Guest', b.owner.guest ?? ''));
      }
    }
    card = new ResCard();
    card.title = 'FINANCIAL RECAP';
    if (b.financialRecap) {
      card.lines.push(
        new ResCardLine(
          'Total Charge',
          coreFormatService.CurrencyAmount(
            !!b.financialRecap.length
              ? b.financialRecap[0].totalCharge ?? 0
              : 0,
            b?.bkgCurrency
          ),
          {
            optionRender: ResCardLineOptionType.AlignRight,
            actions: finacialActions,
          } as IResCardLineOptions
        )
      );
      card.lines.push(
        new ResCardLine(
          'Total Paid',
          coreFormatService.CurrencyAmount(
            !!b.financialRecap.length
              ? b.financialRecap[0].totalPayment ?? 0
              : 0,
            b?.bkgCurrency
          ),
          {
            optionRender: ResCardLineOptionType.AlignRight,
            actions: finacialActions,
          } as IResCardLineOptions
        )
      );
      card.lines.push(
        new ResCardLine(
          'Total Due',
          coreFormatService.CurrencyAmount(
            !!b.financialRecap.length ? b.financialRecap[0].totalDue ?? 0 : 0,
            b?.bkgCurrency
          ),
          { optionRender: ResCardLineOptionType.Total }
        )
      );

      if (
        resService.currentReservationPermission
          .getValue()
          .isDenied(ReservationAction.CanNotSeeCommission).isAllowed
      ) {
        if (!b.isOpenDeposit) {
          //NET Commission
          const netCommission = b.financialRecap[0].netCommission;
          if (netCommission != null) {
            card.lines.push(
              new ResCardLine(
                'NET Commission',
                coreFormatService.CurrencyAmount(netCommission, b?.bkgCurrency),
                {
                  optionRender: ResCardLineOptionType.AlignRight,
                } as IResCardLineOptions
              )
            );
          }
          //VAT Commission
          const vatCommission = b.financialRecap[0].vatCommission;
          if (vatCommission != null) {
            card.lines.push(
              new ResCardLine(
                'VAT Commission',
                coreFormatService.CurrencyAmount(vatCommission, b?.bkgCurrency),
                {
                  optionRender: ResCardLineOptionType.AlignRight,
                } as IResCardLineOptions
              )
            );
          }
          //Total Commission (Commission prepaid)
          const commission = b.financialRecap[0].commission;
          if (commission != null) {
            card.lines.push(
              new ResCardLine(
                // 'Total Commission' + (b.financialRecap[0].prePaid ? ' (Commission prepaid)': ''),
                'Total Commission' +
                (b.financialRecap[0].prePaid ? ' (Commission prepaid)' : ''),
                coreFormatService.CurrencyAmount(commission, b?.bkgCurrency),
                {
                  optionRender: ResCardLineOptionType.AlignRight,
                } as IResCardLineOptions
              )
            );
          }
          //Commission paid
          const commissionPaid = b.financialRecap[0].commissionPaid;
          if (commissionPaid != null) {
            card.lines.push(
              new ResCardLine(
                'Commission paid',
                coreFormatService.CurrencyAmount(
                  commissionPaid,
                  b?.bkgCurrency
                ),
                {
                  optionRender: ResCardLineOptionType.AlignRight,
                } as IResCardLineOptions
              )
            );
          }
          //Booking balance
          const bookingBalance = b.financialRecap[0].bookingBalance;
          if (bookingBalance != null) {
            card.lines.push(
              new ResCardLine(
                'Booking balance',
                coreFormatService.CurrencyAmount(
                  bookingBalance,
                  b?.bkgCurrency
                ),
                { optionRender: ResCardLineOptionType.Total }
              )
            );
          }
        }
      }
    }
    cards.push(card);
    return cards;
  }

  checkSupplmentIsFirst(supplement:BookingJourneySupplement|BookingSupplement,serviceTypeID:number) {
    if(supplement.serviceTypeID !== serviceTypeID) return false
    if(!this.hasCurrentBooking) return false
    const bkg = this.current.getValue()
    if(supplement.itineraryDate!.startOf('day') > bkg.travelStartDate!.startOf('day')) return false
    const s = bkg.journeySupplements.filter(s=>s.serviceTypeID===supplement.serviceTypeID && s.itineraryDate?.isValid)
                                    .find(s=>s.itineraryDate!.startOf('day')<supplement.itineraryDate!.startOf('day'))
    if(s) return false
    return true
  }
  checkSupplmentIsLast(supplement:BookingJourneySupplement|BookingSupplement,serviceTypeID:number) {
    if(supplement.serviceTypeID !== serviceTypeID) return false
    if(!this.hasCurrentBooking) return false
    const bkg = this.current.getValue()
    if(supplement.itineraryDate!.startOf('day') < bkg.travelEndDate!.startOf('day')) return false
    const s = bkg.journeySupplements.filter(s=>s.serviceTypeID===supplement.serviceTypeID && s.itineraryDate?.isValid)
                                    .find(s=>s.itineraryDate!.startOf('day')>supplement.itineraryDate!.startOf('day'))
    if(s) return false
    return true
  }

  loadCardsForBooking(
    coreFormatService: CoreFormatService,
    eidosConfigService: EidosConfigService,
    checkoutMode: boolean = false,
    resService: ReservationService
  ): BookingSectionResCard[] {
    const sidebarCards: BookingSectionResCard[] = [];
    const booking = this.current.getValue();
    let card = new ResCard();
    card = new ResCard();

    const iconFinacialDetails = {
      iconType: EidosIconType.Awesome,
      iconCode: "fa-plus-circle",
      iconSize: 0.6,
    }
    const finacialActions:IResCardLineOptionsActions[] = [
      {
        icon:iconFinacialDetails,
        callback:resService.bookingUtility.viewFinacialDetails.bind(this)
      }
    ]
    if (!booking.isOpenDeposit) {
      card.title = 'GENERAL INFO';

      card.lines.push(new ResCardLine('Status', booking.bkgStatus ?? ''));
      // card.lines.push(new ResCardLine('Type', booking.bkgType ?? ''));
      card.lines.push(new ResCardLine('Company', booking.companyName ?? ''));
      card.lines.push(
        new ResCardLine('Price Type', booking.priceTypeName ?? '')
      );
      card.lines.push(new ResCardLine('Currency', booking.bkgCurrency ?? ''));

      if (booking.getAllGuests().find((g) => g.checkBooking)?.checkBooking) {
        card.lines.push(
          new ResCardLine(
            '',
            `! Guests have another booking on the same voyage`,
            { ishighlighted: true } as IResCardLineOptions
          )
        );
      }

      if (booking.gifComplete === ResBookingGifFlag.Completed) {
        card.lines.push(
          new ResCardLine('', `Guest's GIFs is completed. Thanks`)
        );
      } else if (booking.gifComplete === ResBookingGifFlag.NotCompleted) {
        const advise = booking.owner.isDirect()
          ? 'your Guests'
          : 'Travel Agent or Guests';
        card.lines.push(
          new ResCardLine(
            '',
            `! Please note GIF has not been completed yet – Please advise ${advise}`,
            { ishighlighted: true } as IResCardLineOptions
          )
        );
      }

      // if (booking.travelStartDate && booking.travelStartDate.isValid) {
      //   this.dateStart = booking.travelStartDate;
      // }

      // if (booking.travelEndDate && booking.travelEndDate.isValid) {
      //   this.dateEnd = booking.travelEndDate;
      // }

      const dateStartFormatted: string = !!booking.travelStartDate
        ? booking.travelStartDate.toFormat(
          coreFormatService.DateFmtWithMonthName()
        )
        : '';
      const dateEndFormatted: string = !!booking.travelEndDate
        ? booking.travelEndDate!.toFormat(
          coreFormatService.DateFmtWithMonthName()
        )
        : '';

      const duration = (booking.travelEndDate?.diff(booking.travelStartDate!, ['days']).days ?? 0) + 1
      card.lines.push(
        new ResCardLine('Date', `${dateStartFormatted} - ${dateEndFormatted} (duration: ${duration} days)`)
      );

      booking.sections.forEach((s) => {
        if (s.guestNum > 0) {
          card.lines.push(
            new ResCardLine('Guest N.', s.guestNum.toString(), {
              status: s.sectionID.toString(),
            } as IResCardLineOptions)
          );
        }
      });

      if (booking.isDeleted()) {
        if (booking.cancelDate && booking.cancelDate.isValid) {
          const cancelDateFormatted: string = booking.cancelDate.toFormat(
            coreFormatService.DateFmtWithMonthName()
          );
          card.lines.push(new ResCardLine('Cancel Date', cancelDateFormatted));
        }
      } else {
        if (booking.depositDate && booking.depositDate.isValid) {
          const paymentDueDateFormatted: string = booking.depositDate.toFormat(
            coreFormatService.DateFmtWithMonthName()
          );
          card.lines.push(
            new ResCardLine('Payment Due Date', paymentDueDateFormatted)
          );
        }

        if (booking.getAllVoyages().length > 0) {
          const days = booking.getAllVoyages()[0].daysToSail() ?? 0;
          card.lines.push(
            new ResCardLine(
              'Days to Travel',
              days > 0 ? days.toString() : 'STARTED'
            )
          );
        }
        if (booking.segment){
          card.lines.push(new ResCardLine('Segment', booking.segment))
        }
        if (booking.subSegment){
          card.lines.push(new ResCardLine('Sub Segment', booking.subSegment))
        }
        if (booking.mainDestination){
          card.lines.push(new ResCardLine('Main Destination', booking.mainDestination))
        }
      }

      if (booking.creationDate) {
        card.lines.push(
          new ResCardLine(
            'Created',
            `${booking.createdBy} on ${booking.creationDate?.toFormat(
              coreFormatService.DateFmtWithMonthName()
            )}`
          )
        );
        if (booking.hasUpdateData()) {
          card.lines.push(
            new ResCardLine(
              'Updated',
              `${booking.updatedBy} on ${booking.updated?.toFormat(
                coreFormatService.DateFmtWithMonthName()
              )}`
            )
          );
        }
      }
      if (booking.confirmationDate) {
        card.lines.push(
          new ResCardLine(
            'Confirmed',
            booking.confirmationDate?.toFormat(
              coreFormatService.DateFmtWithMonthName()
            )
          )
        );
      }

      if (booking.grpID ?? 0 > 0) {
        const hyperlink = {
          url: eidosConfigService.getLinks('groupmaster')?.template,
          target: '_blank',
          parameters: { id: booking.grpID },
        } as IResCardLineOptionsHyperlink;
        card.lines.push(
          new ResCardLine(
            'Group',
            booking.grpName.length > 0
              ? `${booking.grpName} - (${booking.grpID})`
              : `${booking.grpID}`,
            { hyperlink: hyperlink } as IResCardLineOptions
          )
        );
      }

      sidebarCards.push(new BookingSectionResCard(0, card));
    }

    card = new ResCard();
    card.title = 'BOOKING OWNER';

    if (booking.owner) {

      const hyperlink = {
        url: eidosConfigService.getLinks('entitymaster')?.template,
        target: '_blank',
        parameters: { entity: 'agency', id: booking.owner.agencyID },
      } as IResCardLineOptionsHyperlink;

      const hyperlinkAgent = {
        url: eidosConfigService.getLinks('entitymaster')?.template,
        target: '_blank',
        parameters: { entity: 'customer', id: booking.owner.individualID },
      } as IResCardLineOptionsHyperlink;

      if (booking.owner.isAgent()) {
        card.lines.push(
          new ResCardLine('Agency', booking.owner.displayName(), {
            hyperlink: hyperlink,
          } as IResCardLineOptions)
        );
        // Add IATA and Agency to the booking owner screen
        if (booking.owner.agencyIataCod)
          card.lines.push(
            new ResCardLine('IATA Code', booking.owner.agencyIataCod ?? '')
          );
        if (booking.owner.individualID) {
          card.lines.push(
            new ResCardLine('Contact', booking.owner.displayContactName(), {
              hyperlink: hyperlinkAgent,
            } as IResCardLineOptions)
          );
        } else {
          card.lines.push(
            new ResCardLine('Contact', booking.owner.displayContactName())
          );
        }
      }
      //Direct booking
      else {
        if (booking.owner.individualID) {
          card.lines.push(
            new ResCardLine('Direct', booking.owner.displayName(), {
              hyperlink: hyperlinkAgent,
            } as IResCardLineOptions)
          );
        }
        else {
          card.lines.push(new ResCardLine('Direct', booking.owner.displayName()));
        }
      }

      // RF - 30.08.2022: Federico's request
      if (!checkoutMode) {
        let address = booking.owner.addressToDisplay;
        if (booking.owner.address2) {
          address += `${!!booking.owner.addressToDisplay ? ' - ' : ''
            } ${!!booking.owner.address2}`;
        }
        card.lines.push(new ResCardLine('Address', address ?? ''));
        card.lines.push(
          new ResCardLine('Country', booking.owner.country ?? '')
        );
      }

      card.lines.push(new ResCardLine('Email', booking.owner.email ?? ''));
      card.lines.push(new ResCardLine('Phone', booking.owner.phone ?? ''));

      if (booking.isOpenDeposit && booking.owner?.displayGuest) {
        card.lines.push(new ResCardLine('Guest', booking.owner.guest ?? ''));
      }
    }
    sidebarCards.push(new BookingSectionResCard(0, card));

    // RF - 30.08.2022: Hide financial recap in checkout as requested by Federico
    if (!checkoutMode) {
      function loadSessionFinancialRecap(
        sectionID: number,
        subtitle: boolean = false
      ) {

        card = new ResCard();
        card.title = `FINANCIAL RECAP ${booking.financialRecap[0]?.commission && booking.commissionPaymentType ? `<span style="font-size:0.8em">(Commission Paid as: ${booking.commissionPaymentType})</span>` : ''}`;
        if (subtitle)
          card.lines.push(
            ResCardLine.createSubtitle(
              sectionID != undefined ? 'VERSION ' + sectionID : ''
            )
          );
        booking.financialRecap
          .filter((f) => f.sectionID == sectionID)
          .forEach((f) => {
            card.lines.push(
              new ResCardLine(
                'Total Charge',
                coreFormatService.CurrencyAmount(
                  f.totalCharge ?? 0,
                  booking?.bkgCurrency
                ),
                {
                  optionRender: ResCardLineOptionType.AlignRight,
                  actions: finacialActions,
                } as IResCardLineOptions
              )
            );
            card.lines.push(
              new ResCardLine(
                'Total Paid',
                coreFormatService.CurrencyAmount(
                  f.totalPayment ?? 0,
                  booking?.bkgCurrency
                ),
                {
                  optionRender: ResCardLineOptionType.AlignRight,
                  actions: finacialActions,
                } as IResCardLineOptions
              )
            );

            //Total Due solo per GROSS PAYER
            if (!booking.financialRecap[0].prePaid) {
              card.lines.push(
                new ResCardLine(
                  'Total Due',
                  coreFormatService.CurrencyAmount(
                    f.totalDue ?? 0,
                    booking?.bkgCurrency
                  ),
                  { optionRender: ResCardLineOptionType.Total }
                )
              );
            }

            if (!booking.isOpenDeposit) {
              if (
                resService.currentReservationPermission
                  .getValue()
                  .isDenied(ReservationAction.CanNotSeeCommission).isAllowed
              ) {
                //Caso GROSS PAYER
                if (!booking.financialRecap[0].prePaid) {
                  //NET Commission
                  if (f.netCommission != null) {
                    card.lines.push(
                      new ResCardLine(
                        'NET Commission',
                        coreFormatService.CurrencyAmount(
                          f.netCommission,
                          booking?.bkgCurrency
                        ),
                        {
                          optionRender: ResCardLineOptionType.AlignRight,
                        } as IResCardLineOptions
                      )
                    );
                  }
                  //VAT Commission
                  if (f.vatCommission != null) {
                    card.lines.push(
                      new ResCardLine(
                        'VAT Commission',
                        coreFormatService.CurrencyAmount(
                          f.vatCommission,
                          booking?.bkgCurrency
                        ),
                        {
                          optionRender: ResCardLineOptionType.AlignRight,
                        } as IResCardLineOptions
                      )
                    );
                  }
                  //Total Commission
                  if (f.commission != null) {
                    card.lines.push(
                      new ResCardLine(
                        'Total Commission',
                        coreFormatService.CurrencyAmount(
                          f.commission ?? 0,
                          booking?.bkgCurrency
                        ),
                        {
                          optionRender: ResCardLineOptionType.AlignRight,
                        } as IResCardLineOptions
                      )
                    );
                  }
                  //Commission paid
                  if (f.commissionPaid != null) {
                    card.lines.push(
                      new ResCardLine(
                        'Commission paid',
                        coreFormatService.CurrencyAmount(
                          f.commissionPaid,
                          booking?.bkgCurrency
                        ),
                        {
                          optionRender: ResCardLineOptionType.AlignRight,
                        } as IResCardLineOptions
                      )
                    );
                  }
                  //Booking balance
                  if (f.bookingBalance != null) {
                    card.lines.push(
                      new ResCardLine(
                        'Booking balance',
                        coreFormatService.CurrencyAmount(
                          f.bookingBalance,
                          booking?.bkgCurrency
                        ),
                        { optionRender: ResCardLineOptionType.Total }
                      )
                    );
                  }
                } else {

                  //
                  //Caso NET PAYER
                  //

                  //NET Commission
                  if (f.netCommission != null) {
                    card.lines.push(
                      new ResCardLine(
                        'NET Commission prepaid',
                        coreFormatService.CurrencyAmount(
                          f.netCommission,
                          booking?.bkgCurrency
                        ),
                        {
                          optionRender: ResCardLineOptionType.AlignRight,
                        } as IResCardLineOptions
                      )
                    );
                  }

                  //VAT Commission
                  if (f.vatCommission != null) {
                    card.lines.push(
                      new ResCardLine(
                        'VAT Commission prepaid',
                        coreFormatService.CurrencyAmount(
                          f.vatCommission,
                          booking?.bkgCurrency
                        ),
                        {
                          optionRender: ResCardLineOptionType.AlignRight,
                        } as IResCardLineOptions
                      )
                    );
                  }

                  //Total Commission prepaid
                  if (f.commission != null) {
                    card.lines.push(
                      new ResCardLine(
                        'Total Commission prepaid',
                        coreFormatService.CurrencyAmount(
                          f.commission ?? 0,
                          booking?.bkgCurrency
                        ),
                        {
                          optionRender: ResCardLineOptionType.AlignRight,
                        } as IResCardLineOptions
                      )
                    );
                  }

                  //Total Due solo per Net PAYER
                  card.lines.push(
                    new ResCardLine(
                      'Total Due',
                      coreFormatService.CurrencyAmount(
                        f.totalDue ?? 0,
                        booking?.bkgCurrency
                      ),
                      { optionRender: ResCardLineOptionType.Total }
                    )
                  );

                  //Commission payment uploaded
                  if (f.commissionPaid != null) {
                    card.lines.push(
                      new ResCardLine(
                        'Commission payment uploaded',
                        coreFormatService.CurrencyAmount(
                          f.commissionPaid,
                          booking?.bkgCurrency
                        ),
                        {
                          optionRender: ResCardLineOptionType.AlignRight,
                        } as IResCardLineOptions
                      )
                    );
                  }
                }
              }
              else {
                //Aggiunta Total Due solo per Net PAYER con CanNotSeeCommission
                if (booking.financialRecap[0].prePaid) {
                  //Caso NET PAYER
                  card.lines.push(
                    new ResCardLine(
                      'Total Due',
                      coreFormatService.CurrencyAmount(
                        f.totalDue ?? 0,
                        booking?.bkgCurrency
                      ),
                      { optionRender: ResCardLineOptionType.Total }
                    )
                  );
                }
              }
            }

            sidebarCards.push(new BookingSectionResCard(sectionID, card));
          });
      }

      if (booking.sections.length === 1) {
        loadSessionFinancialRecap(booking.sections[0].sectionID);
      } else {
        booking.sections.forEach((s) =>
          loadSessionFinancialRecap(s.sectionID, true)
        );
      }

      //booking.Deposit
      function loadSessionPaymentSchedule(
        sectionID: number,
        subtitle: boolean = false
      ) {
        card = new ResCard();
        card.title = 'PAYMENT SCHEDULE';
        if (subtitle)
          card.lines.push(
            ResCardLine.createSubtitle(
              sectionID != undefined ? 'VERSION ' + sectionID : ''
            )
          );
        booking.deposit
          .filter((d) => d.sectionID === sectionID)
          .filter((d) => d.paymentDueDate && d.paymentDueDate.isValid)
          .forEach((d) => {
            card.lines.push(
              new ResCardLine(
                d.paymentDueDate!.toFormat(
                  coreFormatService.DateFmtWithMonthName()
                ),
                (d.displayText + ' ' + coreFormatService.CurrencyAmount(d.amount ?? 0, d.currency)),
                {
                  optionRender: ResCardLineOptionType.AlignRight,
                } as IResCardLineOptions
              )
            );
          });
        sidebarCards.push(new BookingSectionResCard(sectionID, card));
      }
      if (booking.sections.length === 1) {
        loadSessionPaymentSchedule(booking.sections[0].sectionID);
      } else {
        booking.sections.forEach((s) =>
          loadSessionPaymentSchedule(s.sectionID, true)
        );
      }
    }
    // + d.includeInsurance ? '(with Insurance)' : '(without Insurance)'
    if (booking.penalties && !booking.isOpenDeposit) {
      function loadSessionPenalties(sectionID: number) {
        card = new ResCard();
        card.title = 'PENALTIES (Excl. Dest. Fees)';
        let breakKey = '';
        booking.penalties
          .filter((p) => p.sectionID === sectionID)
          .forEach((p) => {
            const daysInNumbers =
              p.daysFrom != null && p.daysTo != null
                ? `${p.daysFrom} / ${p.daysTo}`
                : '';
            const daysInDates =
              p.dateFrom?.isValid && p.dateTo?.isValid
                ? `${p.dateFrom.toFormat(
                  coreFormatService.DateFmtWithMonthName()
                )} / ${p.dateTo.toFormat(
                  coreFormatService.DateFmtWithMonthName()
                )}`
                : '';

            const caption =
              daysInNumbers || daysInDates
                ? `${daysInNumbers} (${daysInDates})`
                : '-';
            const value = p.fixedAmount
              ? coreFormatService.CurrencyAmount(
                p.fixedAmount,
                booking?.bkgCurrency
              )
              : `${coreFormatService.PercentualNumber(
                p.amountPerc ?? 0,
                '1.0-0'
              )} (${coreFormatService.CurrencyAmount(
                p.amountValue ?? 0,
                booking?.bkgCurrency
              )})`;

            const newKey = `${p.packageID}-${p.sectionID}-${p.optionID}-${p.voyageID}`;
            if (newKey !== breakKey) {
              breakKey = newKey;

              function getVoyageDesc(
                voyageID: number,
                voyageName: string
              ): string {
                const voyages = booking.getAllVoyages();
                if (voyages.length < 2) return voyageName;
                const voy = booking
                  .getAllVoyages()
                  .find((v) => v.voyageID === voyageID);
                if (!voy) return voyageName;
                return (
                  voy.displayName() +
                  (voy.embarkDate && voy.embarkDate.isValid
                    ? ' - embark: ' +
                    voy.embarkDate.toFormat(
                      coreFormatService.DateFmtWithMonthName()
                    )
                    : '')
                );
              }

              if (p.packageID && (p.packageName ?? '') !== '' && !booking.multiSuite) {
                card.lines.push(
                  ResCardLine.createSubtitle(p.packageName ?? '')
                );
              }
              if (p.sectionID && (p.serviceLongName ?? '') !== '') {
                if (p.packageName !== p.serviceLongName) {
                  card.lines.push(
                    ResCardLine.createSubtitle(p.serviceLongName ?? '')
                  );
                }
              }
              if (p.optionID && (p.optionName ?? '') !== '') {
                if (p.optionName !== p.packageName && p.optionName !== p.serviceLongName) {
                  card.lines.push(
                    ResCardLine.createSubtitle(p.optionName ?? '')
                  );
                }
              }
              if (p.voyageID) {
                const subtitle = getVoyageDesc(p.voyageID, p.voyageName ?? '');
                card.lines.push(ResCardLine.createSubtitle(subtitle));
              }
            }

            const currentDate = DateTime.local().set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            });
            const isCurrentDateBetweenPenaltyDates =
              booking.isBooked() &&
              p.dateFrom?.isValid &&
              p.dateTo?.isValid &&
              p.dateFrom <= currentDate &&
              (p.dateTo >= currentDate || p.dateTo <= currentDate && p.amountPerc === 100 ) //if penalty is 100% and dateTo is in the past, it is still valid

              //&& (p.packageID === 3 || p.packageID === 11 || p.packageID === 16 )
              //only for Accomodation, Flights and Transport

            card.lines.push(
              new ResCardLine(caption, value, {
                optionRender: ResCardLineOptionType.AlignRight,
                ishighlighted: isCurrentDateBetweenPenaltyDates,
              } as IResCardLineOptions)
            );
          });

          sidebarCards.push(new BookingSectionResCard(sectionID, card));
      }
      booking.sections.forEach((s) => loadSessionPenalties(s.sectionID));
    }

    if (booking.isOpenDeposit) {
      card = new ResCard();
      card.title = 'OTHER INFO';

      card.lines.push(new ResCardLine('Notes', booking.note));

      if (booking.bkgAgentOwner) {
        card.lines.push(new ResCardLine('Agent owner', booking.bkgAgentOwner));
      }
      sidebarCards.push(new BookingSectionResCard(0, card));
    }

    return sidebarCards;
  }
}
