import { DateTime } from "luxon";
import { IMyBizVersion, IMyBizLabel } from "@common/models/mybiz.interface";
import { HttpEventType } from "@angular/common/http";
import { SafeBaseObject } from "./base-object.models";
import { IEidosGridColumnConfiguration, IEidosGridConfiguration } from "./eidos-grid.models";

enum MyBizMessageTypes {
  Error = "err",
  Warning = "war",
  Info = "inf"
};

/**
 * Interface of a MyBiz API response
 *
 * @export
 * @interface IMyBizApiResponse
 */
export interface IMyBizApiResponse {
  Code: string;
  Error: string;
  Type: string;
  Value: any;
}

class MbResponse implements IMyBizApiResponse {
  Code: string = '';
  Error: string = '';
  Type: string = '';
  Value: any = null;
};

export class MyBizApiResponse {

  error: string | null = null;
  type: string | null = null;
  data: any | null = null;

  constructor(response: any) {

    let mbResponse: MbResponse = response;

    if (mbResponse.Type) {
      this.error = mbResponse.Error;
      this.type = mbResponse.Type;
      this.data = mbResponse.Value;
    } else {
      this.type = response.hasError ? 'err' : 'inf';
      this.error = response.hasError ? response.message : '';
      this.data = response.data;
    }

    if (this.type === MyBizMessageTypes.Warning) {
      console.warn(mbResponse.Error);
    } else if (this.type === MyBizMessageTypes.Error) {
      console.error(mbResponse.Error);
    }

    if (this.type === MyBizMessageTypes.Error) {
      throw this.error;
    }
  }

  isOk(): boolean {
    return this.type !== 'err';
  }
}

export class UploadResponse {
  type: HttpEventType | undefined;
  loaded: number = 0;
  total?: number;
  constructor(data?: any) {
    this.type = data.type;
    this.loaded = +data.loaded;
    this.total = data.total;
  }
}

export class EidosSimpleDataResponse extends SafeBaseObject {
  master: Array<{ [key: string]: any }> = [];
  othersGrid: Array<{cfg:IEidosGridConfiguration,data:Array<{ [key: string]: any }>}> = []
  config: IEidosGridConfiguration = {
    itemsKey: '',
    masterDetailEnabled: false,
    masterDetailTemplate: '',
    allowedPageSizes: [],
    rowClickable: true,
    pageSize: 0,
    columns: [],
    hidePager: true,
    showFilterRow: true,
    showHeaderFilter: true,
    showColumnChooser: true,
    showColumnFixing: true,
    showBorders: true,
    hideSelectorButton: true,
    rowAlternationEnabled: true,
    selectionMode: "single"
  };
  hasConfigData = false;
  autoConfig = false;

  constructor(response?: any) {
    super();
    this.updateData(response);
    if (response.data) {
      this.master = response.data;
      if(response.ResultCount>1) {
        for(let i=1; i<response.ResultCount; i++) {

          const data = response[`DT${i}`] as Array<{ [key: string]: any }>

          const cols: Array<IEidosGridColumnConfiguration> = [];
          if (data.length > 0) {
            Object.entries(data[0]).forEach(([key, _]) => {
              cols.push({ dataField: key, caption: key, visible: true, width: '180px' })
            });
          }
          data.forEach((r,idx)=>r['$ROWINDEX']=idx)
          const grid = {
            cfg: {
              itemsKey: '$ROWINDEX',
              masterDetailEnabled: false,
              masterDetailTemplate: '',
              allowedPageSizes: [],
              pageSize: data.length,
              columns: cols,
              hidePager: true,
              showFilterRow: true,
              showHeaderFilter: true,
              showColumnChooser: true,
              showColumnFixing: true,
              showBorders: true,
              rowAlternationEnabled: true,
              selectionMode: "single",
              hideSelectorButton: true,
            } as IEidosGridConfiguration,
            data: data
          }
          this.othersGrid.push(grid)
        }
      }

      if (this.hasConfigData && response.Config) {
        this.config = response.Config;
        this.config.pageSize = this.master.length;
      } else if (this.autoConfig) {
        const cols: Array<IEidosGridColumnConfiguration> = [];
        if (this.master.length > 0) {
          Object.entries(this.master[0]).forEach(([key, _]) => {
            cols.push({ dataField: key, caption: key, visible: true, width: '180px' })
          });
        }
        this.config = {
          itemsKey: '$ROWINDEX',
          masterDetailEnabled: false,
          masterDetailTemplate: '',
          allowedPageSizes: [],
          pageSize: this.master.length,
          columns: cols,
          hidePager: true,
          showFilterRow: true,
          showHeaderFilter: true,
          showColumnChooser: true,
          showColumnFixing: true,
          showBorders: true,
          rowAlternationEnabled: true,
          selectionMode: "single",
          hideSelectorButton: true,
        };
      }
    }
  }
}
export interface UploadFileOptions {
  overwrite: boolean,
  versionSuffix: string,
  versionMinDigit: number,
}
export class MyBizVersionApiResponse {
  /**
   * API version of MyBiz
   *
   * @type {IMyBizVersion}
   * @memberof MyBizVersionApiResponse
   */
  apiVersion?: IMyBizVersion;
  /**
   * DB version of MyBiz
   *
   * @type {IMyBizVersion}
   * @memberof MyBizVersionApiResponse
   */
  dbVersion?: IMyBizVersion;

  constructor(data?: any) {
    if (data) {
      if (data.Db) {
        const dbVersion = data.Db;
        this.dbVersion = {
          applyDate: DateTime.fromFormat(dbVersion.ApplyDate, "y-LL-dd T").toFormat("dd/LL/yyyy HH:mm"),
          notes: dbVersion.Notes,
          version: dbVersion.Version
        }
      }
      if (data.Api) {
        const version = data.Api.Version;
        if (version) {
          this.apiVersion = {
            version: `${version._Major}.${version._Minor}.${version._Build}`,
            revision: version._Revision
          }
        }
      }
    }
  }
}

export class MyBizLabels {

  labels: IMyBizLabel = {};

  constructor(mybizApiResponse?: MyBizApiResponse) {
    if (mybizApiResponse?.data) this.labels = Object.assign({}, mybizApiResponse.data);
  }

  /**
   * Return the specified label, if existing, or undefined
   *
   * @param {string} key
   * @return {*}  {(string | undefined)}
   * @memberof MyBizLabels
   */
  public get(key: string): string | undefined {
    return this.labels[key];
  }
}

/**
 * (From MyBiz API) MyBiz filter types enum
 *
 * @export
 * @enum {number}
 */
export enum MyBizFilterType {
  /// <summary>
  /// La stringa contiene il valore
  /// </summary>
  Contains = 0,

  /// <summary>
  /// La stringa non contiene il valore
  /// </summary>
  NotContains = 1,

  /// <summary>
  /// Il campo non contiene valori
  /// </summary>
  IsNull = 2,

  /// <summary>
  /// Il campo contiene valori
  /// </summary>
  NotIsNull = 3,

  /// <summary>
  /// Il numero o la data sono maggiori del valore
  /// </summary>
  GreaterThan = 4,

  /// <summary>
  /// Il numero o la data sono minori del valore
  /// </summary>
  LesserThan = 5,

  /// <summary>
  /// Default
  /// Il campo contiene esattamente il valore
  /// </summary>
  Equal = 6,

  /// <summary>
  /// Tipo di filtro usato per passare il parametro alle SP
  /// </summary>
  Dummy = 7,

  /// <summary>
  /// Il campo deve contenere un valore diverso dal valore
  /// </summary>
  NotEqual = 8,

  /// <summary>
  /// Il campo deve essere compreso tra due valori
  /// </summary>
  Range = 9,

  /// <summary>
  /// like 'text%'
  /// </summary>
  BeginsWith = 10,

  /// <summary>
  /// like '%text'
  /// </summary>
  EndsWith = 11,

  /// <summary>
  /// La stringa contiene un elenco di valori separati da ||| (3 caratteri pipe)
  /// </summary>
  List = 12,

  /// <summary>
  /// La stringa contiene un elenco di valori separati da ||| (3 caratteri pipe)
  /// </summary>
  ListExcept = 13,
};
